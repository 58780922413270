import * as ko from 'knockout';
import LocationSearcher from '../../common/locationSearch/locationSearcher';
import ConfigurationClient from '../../common/webHostClient/configuration/configurationClient';
import Location from '../../common/locationSearch/location';

import { links } from '../../links';

import storage from '../../common/storage';

export default class StartOrder {

    readonly locationSearcher = new LocationSearcher();
    readonly startInProgress = ko.observable(false);
    readonly canStart: ko.Computed<boolean> = ko.computed(() => null != this.locationSearcher.selectedLocation());
    readonly canStartCurrentLocation = ko.computed(() => this.locationSearcher.canStartGeolocate());

    public async initAsync() {
        const configurationClient: ConfigurationClient = new ConfigurationClient();
        const response = await configurationClient.getOsApiConfigurationAsync();
        this.locationSearcher.settings(response);
    }

    public start(): void {
        this.startInProgress(true);

        if (this.locationSearcher.selectedLocation()) {
            this.doStartOrder(this.locationSearcher.selectedLocation());
        }
    }

    public startCurrentLocation(): void {
        this.startInProgress(true);

        this.locationSearcher.startGeolocate(
            (location: Location) => this.handleGeolocateSuccess(location),
            () => this.startInProgress(false));
    }

    private handleGeolocateSuccess(location: Location): void {
        this.doStartOrder(location);
    }

    private doStartOrder(location: Location): void {

        storage.setStartOrderMapBounds(location.boundingBox.toString());
        storage.setStartOrderLocationQueryText(location.title());
        window.location.assign(links.selectAreaHref);
    }
}
import * as ko from "knockout";

import BrandedPage from '../brandedPage';
import StartOrder from './startOrder';

import "./atkins-homepage.less"

import alertTemplate from "../../templates/alert.html";
import locationSearchTemplate from "../../templates/locationSearch.html";
import startUtilitySearchTemplate from "../../templates/startUtilitySearch.html";

class Home extends BrandedPage {

    readonly startOrder: ko.Observable<StartOrder> = ko.observable(new StartOrder());

    protected getTemplateHtmlSnippets(): string[] {
        const templates = super.getTemplateHtmlSnippets();
        templates.push(alertTemplate);
        templates.push(locationSearchTemplate);
        templates.push(startUtilitySearchTemplate);
        return templates;
    }

    protected async loadContentAsync(): Promise<void> {
        await super.loadContentAsync();

        await this.startOrder().initAsync();

        $('.carousel').carousel({
            interval: 15000
        })
    }   
}

(new Home()).initialiseAsync();

